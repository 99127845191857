import * as React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Link from 'gatsby-link'

const TemplateWrapper = ({ children }) => {
  const location = useLocation()
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          as="style"
          rel="stylesheet preload prefetch"
          href="https://use.typekit.net/ohm3kdp.css"
        ></link>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin
        ></link>
        <link
          as="style"
          rel="stylesheet preload prefetch"
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600&display=swap"
        ></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
      <CookieConsent
        location="bottom"
        onAccept={() => {
          initializeAndTrack(location)
        }}
        children="Text"
        buttonText="Accept"
        enableDeclineButton
        flipButtons
        buttonStyle={{
          textTransform: 'uppercase',
          fontWeight: 300,
          background: 'transparent',
          color: '#4F4F4F',
          border: '1.5px solid #D39F4A',
          borderRadius: '9999px',
          fontSize: '13px',
          letterSpacing: '0.1em',
          padding: '5px 15px',
        }}
        declineButtonStyle={{
          textTransform: 'uppercase',
          fontWeight: 300,
          background: 'transparent',
          color: '#4F4F4F',
          '&:hover': {
            textDecoration: 'underline',
          },
          fontSize: '13px',
          letterSpacing: '0.1em',
          padding: '5px 15px',
        }}
        style={{
          background: '#fffcfa',
          color: '#4F4F4F',
          boxShadow: '-3px 0px 9px rgb(50 50 50 / 33%)',
        }}
        declineButtonText="Deny"
        cookieName="gatsby-gdpr-google-analytics"
      >
        We use cookies to ensure we give you the best experience on our site. By
        continuing to use this site you are agreeing to the{' '}
        <Link style={{ textDecoration: 'underline' }} to="/cookie-policy">
          use of cookies
        </Link>{' '}
        and our{' '}
        <Link style={{ textDecoration: 'underline' }} to="/privacy-policy">
          privacy policy
        </Link>
        .
      </CookieConsent>
    </div>
  )
}

export default TemplateWrapper
