import * as React from 'react'
import { Link } from 'gatsby'

import HorizontalDivider from './HorizontalDivider'

// Uncomment below to enable social links
// import linkedIn from "../img/social/linked-in-logo.svg";
// import facebook from "../img/social/facebook-logo.svg";
// import twitter from "../img/social/twitter-logo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <HorizontalDivider />
        <div className="content">
          <div className="container flex flex-col items-center text-center md:flex-row md:justify-between md:items-start md:mb-2">
            <div className="flex flex-col mb-4 lg:ml-20">
              <h4 className="md:hidden font-medium mb-0">The Joinery</h4>
              <Link className="md:text-left" to="/">
                Home
              </Link>
              <Link className="md:text-left" to="/services">
                Services
              </Link>
              <Link className="md:text-left" to="/process">
                Process
              </Link>
              <Link className="md:text-left" to="/about">
                About
              </Link>
              <Link className="md:text-left" to="/contact">
                Contact
              </Link>
              <Link className="md:text-left" to="/cookie-policy">
                Cookie Policy
              </Link>
              <Link className="md:text-left" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
            <div className="flex flex-col mb-4">
              <h4 className="md:hidden font-medium mb-0">Get In Touch</h4>
              <p className="text-base md:text-left md:text-base">The Joinery</p>
              <p className="text-base md:text-left md:text-base">
                17 Nottingham Street
              </p>
              <p className="text-base md:text-left md:text-base">London</p>
              <p className="text-base md:text-left md:text-base">W1U 5EW</p>
              <a
                className="text-base md:text-left md:text-base"
                href="mailto:milespulver@thejoinery.co.uk"
              >
                milespulver@thejoinery.co.uk
              </a>
            </div>
            {/* Uncomment Below to Enable Social Links */}
            {/* <div className="flex mb-4 md:hidden">
              <a
                title="linkedin"
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="mr-2"
                  src={linkedIn}
                  alt="LinkedIn"
                  style={{ width: '30px', height: '30px' }}
                />
              </a>
              <a
                title="facebook"
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="mr-2"
                  src={facebook}
                  alt="Facebook"
                  style={{ width: '30px', height: '30px' }}
                />
              </a>
              <a
                title="twitter"
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitter}
                  alt="Twitter"
                  style={{ width: '30px', height: '30px' }}
                />
              </a>
            </div> */}
            <div className="flex flex-col mb-4 lg:mr-20">
              <p className="text-base md:text-left font-extralight">
                © The Joinery
              </p>
              <p className="text-base md:text-left font-extralight">
                All Rights Reserved
              </p>
              <p className="text-base md:text-left font-extralight">
                Website design by Minna Down Design
              </p>
              {/* Uncomment Below to Enable Social Links */}
              {/* <div className="hidden mb-4 md:flex md:justify-start md:mt-4">
                <a
                  title="linkedin"
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="mr-2 h-[30px] w-[30px] lg:w-[40px] lg:h-[40px]"
                    src={linkedIn}
                    alt="LinkedIn"
                  />
                </a>
                <a
                  title="facebook"
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="mr-2 h-[30px] w-[30px] lg:w-[40px] lg:h-[40px]"
                    src={facebook}
                    alt="Facebook"
                  />
                </a>
                <a
                  title="twitter"
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-[30px] w-[30px] lg:w-[40px] lg:h-[40px]"
                    src={twitter}
                    alt="Twitter"
                  />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
