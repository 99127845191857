import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import burger from '../img/burger.svg'
import closeMenuBtn from '../img/close-menu-btn.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      open: false,
    }
  }

  toggleHamburger() {
    // stop scrolling of the body behind overlay
    const body = document.getElementsByTagName('body')[0]
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        if (this.state.active) {
          body.classList.add('lock-scroll')
          this.setState({
            navBarActiveClass: 'is-active',
          })
        } else {
          body.classList.remove('lock-scroll')
          this.setState({
            navBarActiveClass: '',
          })
        }
        // set the class in state for the navbar accordingly
      }
    )
  }

  render() {
    return (
      <nav
        className="z-30 relative min-h-full transparent-underlay"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container flex grow relative py-2 md:py-4">
          <div className="w-full justify-between flex shrink-0 items-stretch">
            <Link to="/" className="navbar-item" title="Logo">
              <img
                src={logo}
                alt="The Joinery"
                width="88"
                height="65"
                className="md:w-32"
              />
            </Link>
            {/* Hamburger menu */}
            <button
              data-target="navMenu"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleHamburger()}
              onClick={() => this.toggleHamburger()}
            >
              <img
                src={burger}
                alt="Burger menu"
                width="65px"
                height="28px"
                className="w-6 md:w-12"
              />
            </button>
          </div>
          <div
            id="navMenu"
            className={`px-4 z-10 py-4 flex-col fixed lightblue-background bg-[#98dce4] top-0 left-0 h-screen w-screen md:absolute md:w-fit md:h-fit md:right-4 md:left-auto md:top-10 md:py-2 md:px-2 ${
              this.state.navBarActiveClass ? 'flex' : 'hidden'
            }`}
          >
            <div className="flex justify-end">
              {/* Close Hamburger menu */}
              <button
                data-target="navMenu"
                role="menuitem"
                tabIndex={0}
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
              >
                <img
                  src={closeMenuBtn}
                  alt="Close Menu Button"
                  className="w-[36px] h-[38px] md:w-[18px] md:h-[20px]"
                />
              </button>
            </div>
            <div className="flex flex-col justify-center h-full items-center md:pl-8 md:pr-8 md:pt-4 md:pb-4">
              <Link
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
                className="palewhite-text text-palewhite text-3xl mb-8 font-light space-letters md:text-xl md:mb-2 lg:mb-4 lg:text-[26px]"
                to="/"
              >
                Home
              </Link>
              <Link
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
                className="palewhite-text text-palewhite text-3xl mb-8 font-light space-letters md:text-xl md:mb-2 lg:mb-4 lg:text-[26px]"
                to="/services"
              >
                Services
              </Link>
              <Link
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
                className="palewhite-text text-palewhite text-3xl mb-8 font-light space-letters md:text-xl md:mb-2 lg:mb-4 lg:text-[26px]"
                to="/process"
              >
                Process
              </Link>
              <Link
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
                className="palewhite-text text-palewhite text-3xl mb-8 font-light space-letters md:text-xl md:mb-2 lg:mb-4 lg:text-[26px]"
                to="/about"
              >
                About
              </Link>
              <Link
                onKeyPress={() => this.toggleHamburger()}
                onClick={() => this.toggleHamburger()}
                className="palewhite-text text-palewhite text-3xl font-light md:text-xl md:mb-4 lg:text-[26px]"
                to="/contact"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
